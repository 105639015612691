<template>
  <div class="page sitemap" loaded>
    <div class="wrapper">
      <h1>WestBIC - Site map</h1>
      <div class="container">
        <div class="block">
          <h4>General</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/case-studies">Our clients</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div class="block">
          <h4>What we do</h4>
          <ul>
            <li><a href="/what-we-do">What we do</a></li>
            <li><a href="/about-us">About us</a></li>
            <li><a href="/bic-network">BIC network</a></li>
            <li><a href="/our-team">Our team</a></li>
          </ul>
        </div>
        <div class="block">
          <h4>News & events</h4>
          <ul>
            <li><a href="/news">In the news</a></li>
            <li><a href="/events">Events</a></li>
            <li><a href="/podcast">Podcast</a></li>
          </ul>
        </div>
        <div class="block">
          <h4>Collaborative projects</h4>
          <ul>
            <li><a href="/initiatives">Collaborative projects</a></li>
            <li><a href="/incubation">Incubation</a></li>
            <li><a href="/partners">Partners</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sitemap",
};
</script>

<style scoped>
.wrapper {
  width: 800px;
  margin: auto;
  padding: 48px 24px 80px;
}

.container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.container .block {
  width: 33.3333333%;
  margin-bottom: 24px;
}

h1 {
  border-bottom: 2px solid #eee;
  padding-bottom: 16px;
  margin-bottom: 24px;
  font-size: 24px;
}

h4 {
  font-size: 16px;
  margin-bottom: 16px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  margin-bottom: 8px;
}

a {
  text-decoration: none;
  color: #0A7E8C;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 580px) {
  .container .block {
    width: 50%;
  }
}

@media screen and (max-width: 420px) {
  .container .block {
    width: 100%;
  }
}
</style>
